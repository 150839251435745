import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function RouteObserver({ children }) {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.pathname === '/admin/settings') {
      history.replace('/admin/settings/system');
    }

    if (location.pathname === '/admin/settings/registrar') {
      history.replace('/admin/settings/registrar/courses');
    }
  }, [location.pathname]); // eslint-disable-line

  return children;
}

export default RouteObserver;
