import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
    justifyContent: "center",
  },
});
function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h5" align="center">
        404 | Not Found
      </Typography>
    </div>
  );
}

export default NotFound;
