import { dashboardRoutes } from "../route/routes";

export const useRouteName = () => {
  let name = "";
  dashboardRoutes.forEach((route) => {
    if (window.location.href.indexOf(route.layout + route.path) !== -1) {
      name = route.name;
    }
  });
  return name;
};
