import { TextField } from '@material-ui/core';
import React from 'react';

import PropTypes from 'prop-types';

function FormField(props) {
  const { errors, customError, name = '', shrinkLabel, ...rest } = props;

  let error = false;
  let helperText = '';

  if (errors) {
    error = (errors && errors.has(name)) || false;
    helperText = (errors && errors.first(name)) || '';
  }

  // handle customError
  if (customError) {
    error = customError.error || false;
    helperText = customError.message || '';
  }

  let shrinkProps = {};

  if (shrinkLabel) {
    shrinkProps = {
      InputLabelProps: {
        shrink: true,
      },
    };
  }

  const newProps = {
    variant: 'outlined',
    margin: 'dense',
    fullWidth: true,
    error,
    name,
    helperText,
    ...shrinkProps,
    ...rest,
    value: rest.value || '',
  };

  return <TextField {...newProps} />;
}

FormField.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  customError: PropTypes.any,
  shrinkLabel: PropTypes.bool,
};

export default FormField;
