import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { getLevel, getSemester } from '../../../utils/helpers';
import palletes from '../../../assets/styles/palletes';
import * as service from '../studentService';
import PrintIcon from '@material-ui/icons/Print';
import BasicDataTable from '../../../components/BasicDataTable';
import { useLocation } from 'react-router';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';

const useStyles = makeStyles({
  notCleared: {
    backgroundColor: palletes.error,
    color: '#fff',
    padding: '4px 12px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  cleared: {
    backgroundColor: palletes.success,
    color: '#fff',
    textAlign: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
  },
  inc: {
    backgroundColor: palletes.warning,
    color: '#fff',
    textAlign: 'center',
    padding: '4px 12px',
    whiteSpace: 'nowrap',
  },
  print: {
    backgroundColor: palletes.error,
    color: '#fff',
    '&:hover': {
      backgroundColor: palletes.error,
    },
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const format = 'YYYY-MM-DD';

function Grades() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(); // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    setLoading(true);
    service
      .getAllLoads()
      .then((res) => {
        if (res.data.data) {
          setAccounts(
            res.data.data.map((account) => ({
              ...account,
              loads: account.loads.map((load) => ({
                ...load,
                show_midterm_grade: account.show_midterm_grade,
                show_final_grade: account.show_final_grade,
              })),
            })) || []
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: 'subjectOffer.prospectus.code',
      label: 'Code',
    },
    {
      name: 'subjectOffer.prospectus.descriptive_title',
      label: 'Description',
    },
    {
      name: 'subjectOffer.section',
      label: 'Section',
    },
    {
      name: 'subjectOffer',
      label: 'Instructor',
      options: {
        customBodyRender: (value) => {
          if (value.employee) {
            return value.employee.partial_name;
          }
          return '';
        },
      },
    },
    {
      name: 'subjectOffer',
      label: 'Schedule',
      options: {
        customBodyRender: (value) => {
          return (value && value.subject_schedule) || '';
        },
      },
    },
    {
      name: 'midterm_grade',
      label: 'Midterm Grade',
      options: {
        customBodyRender: (value, item) => {
          if (!item.show_midterm_grade) {
            return showPaymentRequired();
          }

          return getStatus(value);
        },
      },
    },
    {
      name: 'grade',
      label: 'Final Grade',
      options: {
        customBodyRender: (value, item) => {
          if (!item.show_final_grade) {
            return showPaymentRequired();
          }
          return getStatus(value);
        },
      },
    },
    {
      name: 're_exam',
      label: 'Re-exam',
      options: {
        customBodyRender: (value, item) => {
          if (item.grade === 'INC' && !item.show_final_grade) {
            return showPaymentRequired();
          }

          if (item.grade === 'INC' || parseInt(item.grade) > 3) {
            return getStatus(value);
          }
        },
      },
    },
  ];

  const showPaymentRequired = () => {
    return (
      <Box textAlign="center">
        <IconButton size="small" onClick={handleNavigateBilling}>
          <LockIcon color="error" />
        </IconButton>
      </Box>
    );
  };

  const handleNavigateBilling = () => {
    history.push('/admin/student/billing');
  };

  const getStatus = (value = '') => {
    if (!value) {
      return <span className={classes.notCleared}>No Grade Yet</span>;
    }

    return <span className={value === 'INC' ? classes.inc : classes.cleared}>{value}</span>;
  };

  const handlePrint = (id) => {
    const win = window.open(`${location.pathname.replace('/admin', '')}/print/${id}`, '_blank');
    win.focus();
  };

  return (
    <Fragment>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        accounts.map((account, i) => (
          <Box padding={2} key={i}>
            <Box padding={1} className={classes.headerBox}>
              {account.course ? (
                <Typography>
                  <span>{account.course.code}: </span>
                  <span>{getLevel(account.level)} - </span>
                  <span>{getSemester(account.semester)} Semester, </span>
                  <span>{account.school_year}</span>
                </Typography>
              ) : (
                <Typography gutterBottom>
                  {`Grade ${account.level}, ${account.school_year}, ${getSemester(
                    account.semester,
                    true
                  )}`}
                </Typography>
              )}

              <Button className={classes.print} onClick={() => handlePrint(account.id)}>
                <PrintIcon /> Print
              </Button>
            </Box>
            <BasicDataTable size="small" withIndex data={account.loads || []} columns={columns} />
          </Box>
        ))
      )}
    </Fragment>
  );
}

export default Grades;
