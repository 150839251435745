import { DialogContent } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import FormDialog from 'components/FormDialog';
import FormField from 'components/FormField';
import FormSelectField from 'components/FormSelectField';
import { showNofications } from 'modules/layouts/store/actionCreators';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import settingsService from 'services/SettingService';
import { handleResponseError, paymentRequirementType, Revalidate } from 'utils/helpers';

const validator = Revalidate({
  type: 'required',
  amount: 'required',
});

function EditPaymentSettings({ open, onClose, values, refresh }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    values: {
      type: 'Fixed',
      amount: '',
    },
    errors: validator.errors,
  });

  useEffect(() => {
    if (open) {
      setFormValues((prev) => ({
        ...prev,
        values: {
          type: values.scope || 'Fixed',
          amount: values.value || '',
        },
      }));
    }
  }, [open]); // eslint-disable-line

  const toggleLoading = () => setLoading((prev) => !prev);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const { errors } = validator;

    const newValues = {
      [name]: value,
    };

    if (name === 'type' && value !== formValues.values[name]) {
      newValues.amount = '';
    }

    setFormValues((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...newValues,
      },
    }));

    validator.validate(name, value).then(() => {
      setFormValues((prev) => ({
        ...prev,
        errors,
      }));
    });
  };

  const handleKeyPress = (e) => {
    if (!/^[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleValidate = () => {
    validator.validateAll(formValues.values).then((status) => {
      if (status) {
        handleSubmit();
      } else {
        setFormValues((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  const handleSubmit = () => {
    toggleLoading();
    settingsService
      .update(values.id, {
        value: formValues.values.amount,
        scope: formValues.values.type,
      })
      .then((res) => {
        if (res.data.id) {
          dispatch(showNofications('Setting successfully updated'));
          refresh();
          onClose();
        }
        toggleLoading();
      })
      .catch((err) => {
        toggleLoading();
        dispatch(showNofications(handleResponseError(err), 'error'));
      });
  };

  return (
    <FormDialog open={open} onClose={onClose} title="Minimum Payment Requirement">
      <DialogContent>
        <FormSelectField
          name="type"
          value={formValues.values.type}
          onChange={handleChange}
          label="Type"
          options={paymentRequirementType}
          noOptions
          errors={formValues.errors}
        />
        <FormField
          name="amount"
          value={formValues.values.amount}
          onChange={handleChange}
          label="Amount"
          onKeyPress={handleKeyPress}
          errors={formValues.errors}
        />

        <CustomButton disabled={loading} onClick={handleValidate}>
          Update
        </CustomButton>
      </DialogContent>
    </FormDialog>
  );
}

export default EditPaymentSettings;
