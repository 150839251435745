import { handleResponseError } from "../../../utils/helpers";
import Http from "../../../utils/Http";
import { showNofications } from "../../layouts/store/actionCreators";
import * as actions from "./store/actionCreators";

export const getRoles = () => {
  return Http.get("/api/roles");
};

export const getPermissions = () => {
  return Http.get("/api/permissions");
};

export const createPermission = (params) => {
  return Http.post("/api/permissions", params);
};

export const updatePermission = (id, params) => {
  return Http.put(`/api/permissions/${id}`, params);
};

export const getPermissionsByRoles = (role) => {
  return Http.get("/api/roles/permissions-by-role", { params: { role } });
};

export const updatePermissionsByRoles = (role, permissions) => {
  return Http.post("/api/roles/permissions/update", { role, permissions });
};

export const getSettings = (params = {}) => {
  return (dispatch) => {
    dispatch(actions.requestFetchSettings);
    return Http.get("/api/settings", params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.fetchSettings(res.data || {}));
          return true;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failFetchSettings(err));
      });
  };
};

export const updateBasicSettings = (params = {}) => {
  return (dispatch) => {
    dispatch(actions.requestUpdateBasicSettings());
    return Http.post("/api/updateBasicSetting", params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.updateBasicSettings(res.data || {}));
          dispatch(showNofications("Basic Setting Successfully Updated"));
          return true;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failUpdateBasicSettings(err));
      });
  };
};
