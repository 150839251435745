import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormSelectField from "./FormSelectField";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  arrows: {
    fontSize: 22,
    color: "#000",
  },
  label: {
    fontSize: "0.9rem",
    whiteSpace: "nowrap",
  },
  pageBox: {
    display: "flex",
    alignItems: "center",

    "& button:disabled svg": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  rowPerPageBox: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    maxWidth: 60,
    border: "none",
    backgroundColor: "none",

    "& .MuiSelect-root": {
      fontSize: "0.9rem",
      paddingTop: 0,
      paddingBottom: 4,
      paddingLeft: 10,
      paddingRight: 28,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

function CustomPagination({
  page = 0,
  rowsPerPageOptions = [15, 20, 25],
  rowsPerPage = 15,
  count = 0,
  onChangePage,
  onChangeRowsPerPage,
}) {
  const classes = useStyles();

  const [perPage, setPerPage] = useState(rowsPerPage);

  useEffect(() => {
    setPerPage(rowsPerPage);
  }, [rowsPerPage]);

  const handlePerPage = (e) => {
    onChangeRowsPerPage(e);
    setPerPage(e.target.value);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.rowPerPageBox}>
        <Typography className={classes.label}>Rows per page:</Typography>
        <FormSelectField
          noOptions
          onChange={handlePerPage}
          value={perPage}
          className={classes.select}
          options={rowsPerPageOptions}
        />
      </Box>
      {/* <Typography className={classes.label}>0-{count}</Typography> */}
      <Box className={classes.pageBox}>
        <IconButton
          disabled={page === 1}
          onClick={(e) => onChangePage(e, page === 1 ? 1 : page - 2)}
        >
          <ChevronLeftIcon className={classes.arrows} />
        </IconButton>
        <Typography className={classes.label}>{page}</Typography>
        <IconButton
          disabled={count === 0 || count < rowsPerPage}
          onClick={(e) => onChangePage(e, page)}
        >
          <ChevronRightIcon className={classes.arrows} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CustomPagination;
