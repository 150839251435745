import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import FormDialog from "../../components/FormDialog";
import PropTypes from "prop-types";
import { Fragment } from "react";

function LogoutModal({ open, onClose, handleLogout }) {
  const user = useSelector((state) => state.user);

  return (
    <FormDialog open={open} onClose={onClose}>
      <DialogTitle>Logout?</DialogTitle>
      <DialogContent>
        <Typography color="error">Are you sure you want to logout?</Typography>
      </DialogContent>
      <DialogActions>
        {user.pendingLogout ? (
          <CircularProgress />
        ) : (
          <Fragment>
            <Button variant="contained" onClick={handleLogout} color="primary">
              Yes
            </Button>
            <Button variant="contained" color="primary" onClick={onClose}>
              No
            </Button>
          </Fragment>
        )}
      </DialogActions>
    </FormDialog>
  );
}

LogoutModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleLogout: PropTypes.func,
};

export default LogoutModal;
