import React from "react";
import { Route, BrowserRouter as ReactRouter, Switch } from "react-router-dom";
import NotFound from "./modules/notfound/pages/NotFound";
import Admin from "./layouts/Admin";
import Public from "./route/Public";
import publicRoute from "./route/publicRoute";
import { Fragment } from "react";
import RouteObserver from "./route/RouteObserver";

function App() {
  return (
    <Fragment>
      <ReactRouter>
        <RouteObserver>
          <Switch>
            <Route path="/admin" component={Admin} />

            {publicRoute.map((route, i) => (
              <Public exact key={i} {...route} />
            ))}
            <Route component={NotFound} />
          </Switch>
        </RouteObserver>
      </ReactRouter>
    </Fragment>
  );
}

export default App;
