import { Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

function SettingsTab() {
  const location = useLocation();
  const history = useHistory();
  const [currentTab] = useState(location.pathname);

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  const tabList = [
    {
      label: 'System',
      path: '/admin/settings/system',
    },
    {
      label: 'Payments',
      path: '/admin/settings/payments',
    },
    {
      label: 'Enrollments',
      path: '/admin/settings/enrollments',
    },
  ];

  return (
    <Tabs
      value={currentTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="disabled tabs example"
    >
      {tabList.map((val, index) => (
        <Tab
          key={index}
          label={val.label}
          value={val.path}
          style={{ backgroundColor: val.path === location.pathname ? '#fff' : 'unset' }}
        />
      ))}
    </Tabs>
  );
}

export default SettingsTab;
