import React, { useEffect } from "react";
import { Switch, Redirect, useLocation, useHistory } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "./Navbar.js";
// import Footer from "components/Footer/Footer.js";

import { dashboardRoutes } from "../route/routes.js";

import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "../assets/img/bg.jpg";
import logo from "../assets/img/MLG_Logo.png";
import { isAuth } from "../utils/helpers.js";
import { useDispatch, useSelector } from "react-redux";
import * as userService from "../modules/admin/user/service";
import * as informationService from "../modules/information/service";
import * as settingService from "../modules/admin/setting/service";
import HeaderNotifications from "../modules/layouts/HeaderNotifications.js";
import Sidebar from "./Sidebar.js";
import Private from "../route/Private.js";
import { setCookie } from "../utils/cookie.js";
import { systemRoles } from "../utils/helpers.js";
import { Typography } from "@material-ui/core";

let ps;

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isMaintenance = useSelector(
    (state) => state.maintenanceInfo.maintenance
  );
  const roles = user.data.roles || [];

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (isAuth()) {
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);
      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPanel]);

  useEffect(() => {
    init(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.data.id]);

  const init = async () => {
    if (!user.data.id) {
      await dispatch(userService.getUser()).then((res) => {
        if (res && res.data && res.data.data) {
          const values = res.data.data;

          if (values.first_login === "1") {
            setCookie("_login", values.email, 1);
            localStorage.removeItem("accessToken");
            history.push("/first-login");
          }
        }
      });
    }

    await dispatch(informationService.getInformations());
    await dispatch(settingService.getSettings());

    if (
      !roles.includes(systemRoles.student) &&
      roles[0] &&
      !roles[0].includes("Instructor")
    ) {
      if (process.env.REACT_APP_ENV !== "development") {
        const timer = setInterval(() => {
          dispatch(informationService.getInformations());
        }, 10000);

        return () => {
          clearInterval(timer);
        };
      }
    }
  };

  if (!isAuth()) {
    localStorage.removeItem("accessToken");
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.wrapper}>
      <HeaderNotifications />
      <Sidebar
        logoText={"MLGCL Portal"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="blue"
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          {isMaintenance && (
            <div className={classes.maintenance}>
              <Typography align="center">
                Portal is under maintenance
              </Typography>
            </div>
          )}

          <div className={classes.container}>
            <Switch>
              {dashboardRoutes.map((prop, key) => (
                <Private
                  {...prop}
                  exact
                  path={prop.layout + prop.path}
                  key={key}
                />
              ))}

              {location.pathname === "/admin" && (
                <Redirect from="/admin" to="/admin/dashboard" />
              )}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
