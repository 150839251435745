import { Box, makeStyles, Snackbar, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./store/actionCreators";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles({
  success: {
    backgroundColor: "#4caf50",
    padding: "12px 16px",
    borderRadius: 5,
  },
  error: {
    backgroundColor: "#f44336",
    padding: "12px 16px",
  },
  message: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
  },
  icons: {
    marginRight: 5,
  },
});
function HeaderNotifications() {
  const classes = useStyles();
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.hideNotifications());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={notifications.show}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Box
        className={
          notifications.type === "success" ? classes.success : classes.error
        }
      >
        <div className={classes.message}>
          {notifications.type === "success" ? (
            <CheckCircleOutlineIcon className={classes.icons} />
          ) : (
            <ErrorIcon className={classes.icons} />
          )}
          <Typography>{notifications.message}</Typography>
        </div>
      </Box>
    </Snackbar>
  );
}

export default HeaderNotifications;
