import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormSelectField from '../../../../components/FormSelectField';
import { schoolYearOptions, semesterOptions } from '../../../../utils/helpers';
import * as service from '../service';
import SettingsTab from '../components/SettingsTab';

function System() {
  const settings = useSelector((state) => state.settings);
  const currentSettings = settings.data;
  const dispatch = useDispatch();

  const [basicSetting, setBasicSetting] = useState(settings.data);

  useEffect(() => {
    setBasicSetting(currentSettings); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSettings]);

  const handleBasicSettingChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setBasicSetting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSwitchChange = (name) => {
    setBasicSetting((prev) => ({
      ...prev,
      [name]: prev[name] === '1' ? '0' : '1',
    }));
  };

  const updateBasicSetting = () => {
    dispatch(service.updateBasicSettings(basicSetting));
  };

  const getSwitchData = (value) => {
    if (value === '1') {
      return true;
    }

    return false;
  };

  return (
    <Fragment>
      <SettingsTab />
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <FormSelectField
                label="School Year"
                name="school_year"
                onChange={handleBasicSettingChange}
                value={basicSetting.school_year || ''}
                options={schoolYearOptions()}
                noOptions
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormSelectField
                label="Semester"
                name="semester"
                keyValuePair="id,label"
                onChange={handleBasicSettingChange}
                value={basicSetting.semester || ''}
                options={semesterOptions()}
                noOptions
              />
            </Grid>
          </Grid>
          <Box paddingTop={1}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Maintenance Mode</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={getSwitchData(basicSetting.maintenance)}
                      onChange={() => handleSwitchChange('maintenance')}
                    />
                  }
                  label={getSwitchData(basicSetting.maintenance) ? 'Active' : 'Inactive'}
                />
              </FormGroup>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
      <Box marginTop={2}>
        <FormControl margin="dense">
          <Button
            variant="contained"
            color="primary"
            onClick={updateBasicSetting}
            disabled={settings.updating}
          >
            {settings.updating ? <CircularProgress size={20} /> : 'Update'}
          </Button>
        </FormControl>
      </Box>
    </Fragment>
  );
}

export default System;
