import * as types from "./actionTypes";

// fetching setttings
export function requestFetchSettings() {
  return {
    type: types.REQUEST_FETCH_SETTINGS,
  };
}

export function fetchSettings(data) {
  return {
    type: types.FETCH_SETTINGS,
    payload: data,
  };
}

export function failFetchSettings(data) {
  return {
    type: types.FAIL_FETCH_SETTINGS,
    payload: data,
  };
}

// update setttings
export function requestUpdateBasicSettings() {
  return {
    type: types.REQUEST_UPDATE_BASIC_SETTINGS,
  };
}

export function updateBasicSettings(data) {
  return {
    type: types.UPDATE_BASIC_SETTINGS,
    payload: data,
  };
}

export function failUpdateBasicSettings(data) {
  return {
    type: types.FAIL_UPDATE_BASIC_SETTINGS,
    payload: data,
  };
}
