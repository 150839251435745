import React, { useState } from "react";
import FormField from "../../../../components/FormField";
import FormSelectField from "../../../../components/FormSelectField";
import FormDialog from "../../../../components/FormDialog";
import { Revalidate } from "../../../../utils/helpers";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as service from "../service";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";

const validator = Revalidate({
  name: "required",
  course_id: "required",
});

function AddForm(props) {
  const { open, onClose, courses = [] } = props;
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const curriculum = useSelector((state) => state.curriculum);

  const [formValues, setFormValues] = useState({
    values: {
      name: "",
      course_id: "",
    },
    errors: validator.errors,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { errors } = validator;

    errors.remove(name);

    setFormValues((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: value,
      },
    }));

    validator
      .validate(name, value)
      .then(() =>
        setFormValues((prev) => ({ ...prev, errors: validator.errors }))
      );
  };

  const handleSubmit = () => {
    validator.validateAll(formValues.values).then((success) => {
      if (success) {
        dispatch(service.createCurriculum(formValues.values)).then((data) => {
          if (data) {
            history.push(`${location.pathname}/edit/${data.id}`);
          }
        });
      } else {
        setFormValues((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  return (
    <FormDialog open={open} onClose={onClose}>
      <DialogTitle>New Curriculum</DialogTitle>
      <DialogContent>
        <FormField
          name="name"
          label="Curriculum Year"
          value={formValues.values.name}
          onChange={handleChange}
          errors={formValues.errors}
        />
        <FormSelectField
          name="course_id"
          label="Course"
          value={formValues.values.course_id}
          options={courses}
          keyValuePair="id,code"
          onChange={handleChange}
          errors={formValues.errors}
        />

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            disabled={curriculum.creating}
          >
            {curriculum.creating ? <CircularProgress /> : "Submit"}
          </Button>
        </div>
      </DialogContent>
    </FormDialog>
  );
}

AddForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  courses: PropTypes.array,
};

export default AddForm;
