export const REQUEST_FETCH_PROSPECTUSES = "REQUEST_FETCH_PROSPECTUSES";
export const FETCH_PROSPECTUSES = "FETCH_PROSPECTUSES";
export const FAIL_FETCH_PROSPECTUSES = "FAIL_FETCH_PROSPECTUSES";

export const REQUEST_CREATE_PROSPECTUS = "REQUEST_CREATE_PROSPECTUS";
export const CREATE_PROSPECTUS = "CREATE_PROSPECTUS";
export const FAIL_CREATE_PROSPECTUS = "FAIL_CREATE_PROSPECTUS";

export const REQUEST_UPDATE_PROSPECTUS = "REQUEST_UPDATE_PROSPECTUS";
export const UPDATE_PROSPECTUS = "UPDATE_PROSPECTUS";
export const FAIL_UPDATE_PROSPECTUS = "FAIL_UPDATE_PROSPECTUS";
