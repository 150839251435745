import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import FormDialog from "../../../../components/FormDialog";

import PropTypes from "prop-types";

function ChangeStatusConfirmation({ open, onClose, handleConfirm, loading }) {
  return (
    <FormDialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Status Change</DialogTitle>
      <DialogContent>
        <Typography color="error">
          Are you sure you want to change status for this user?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleConfirm}
        >
          {loading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onClose}
        >
          {loading ? <CircularProgress size={24} /> : "Close"}
        </Button>
      </DialogActions>
    </FormDialog>
  );
}

ChangeStatusConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

export default ChangeStatusConfirmation;
