import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import FormDialog from "../../../../components/FormDialog";
import palletes from "../../../../assets/styles/palletes";

import PropTypes from "prop-types";

const useStyles = makeStyles({
  title: {
    color: palletes.error,
  },
  button: {
    paddingLeft: 40,
    paddingRight: 40,
    margin: 5,
  },
  buttonReject: {
    backgroundColor: "#e3342f",
    "&:hover": {
      backgroundColor: "#e3342f",
    },
  },
});

function RemoveEnrolledSubjectModal({ open, onClose, handleRemove, loading }) {
  const classes = useStyles();

  return (
    <FormDialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>
        Remove Enrolled Subject?
      </DialogTitle>
      <DialogContent>
        <Typography color="error">
          Are you sure you want to remove this subject?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={`${classes.button} ${classes.buttonReject}`}
          variant="contained"
          color="primary"
          onClick={handleRemove}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </FormDialog>
  );
}

RemoveEnrolledSubjectModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleRemove: PropTypes.func,
  loading: PropTypes.bool,
};

export default RemoveEnrolledSubjectModal;
