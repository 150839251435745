import { handleResponseError } from "../../../utils/helpers";
import Http from "../../../utils/Http";
import { showNofications } from "../../layouts/store/actionCreators";
import * as actions from "./store/actionCreators";
import axios from "axios";

const CancelToken = axios.CancelToken;
let cancel;

export const getUser = () => {
  return (dispatch) => {
    dispatch(actions.requestGetUser());
    return Http.get("/api/user")
      .then((res) => {
        if (res.data.data) {
          dispatch(actions.getUser(res.data.data));
        }

        return res;
      })

      .catch((err) => {
        dispatch(actions.failedGetUser(err));
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };
};

export const resetPassword = (id) => {
  return Http.get(`/api/users/reset-password/${id}`);
};

export const logout = () => {
  return (dispatch) => {
    dispatch(actions.requestLogoutUser());
    return Http.get("/api/logout")
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("expires_in");
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
        return true;
      })
      .catch((err) => {
        dispatch(actions.failedLogoutUser(err));
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };
};

export const getAllRoles = () => {
  return Http.get("/api/roles");
};

export const getDuplicateUsers = (params = {}) => {
  if (cancel) cancel();

  return Http.get("/api/users/duplicates", {
    params,
    cancelToken: new CancelToken((c) => (cancel = c)),
  });
};

export const deleteDuplicateUser = (id) => {
  return Http.delete(`/api/users/duplicates/${id}`);
};

export const getAllUsers = (params = {}) => {
  if (cancel) cancel();

  return Http.get("/api/users", {
    params,
    cancelToken: new CancelToken((c) => (cancel = c)),
  });
};

export const createUser = (params = {}) => {
  const newParams = {
    birthdate: "No Data",
    religion: "No Data",
    civil_status: "No Data",
    gender: "No Data",
    block: "No Data",
    street: "No Data",
    barangay: "No Data",
    municipality: "No Data",
    province: "No Data",
    zipcode: "No Data",
    emergency_contact_name: "No Data",
    emergency_contact_number: "No Data",
    school_graduated_name: "No Data",
    school_graduated_year: "No Data",
    school_graduated_address: "No Data",
    ...params,
  };

  return Http.post("/api/users", newParams);
};

export const updateUser = (params = {}, id) => {
  return Http.put(`/api/users/${id}`, params);
};

export const updateUserStatus = (params = {}, id) => {
  return Http.put(`/api/users/updateStatus/${id}`, params);
};
