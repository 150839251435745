import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormField from "../../../../components/FormField";
import EditIcon from "@material-ui/icons/Edit";
import * as service from "../service";
import ViewEnrollment from "../components/ViewEnrollment";
import palletes from "../../../../assets/styles/palletes";
import Card from "../../../../components/Card/Card";
import { getLevel } from "../../../../utils/helpers";
import FormSelectField from "../../../../components/FormSelectField";
import { schoolYearOptions } from "../../../../utils/helpers";
import { semesterOptions } from "../../../../utils/helpers";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import { levelOptions } from "../../../../utils/helpers";
import ShiftCourse from "../components/ShiftCourse";
import { useHistory } from "react-router-dom";
import { showNofications } from "../../../../modules/layouts/store/actionCreators";
import { handleResponseError } from "../../../../utils/helpers";
import BasicDataTable from "../../../../components/BasicDataTable";
import useHasPermission from "../../../../utils/permissionHelper";
import { DeleteForeverOutlined } from "@material-ui/icons";
import PromptModal from "../../../../components/PromptModal";

const useStyles = makeStyles({
  searchBox: {
    padding: "12px 16px",
  },
  pending: {
    backgroundColor: "#ffed4a",
    color: "#000",
    padding: 4,
  },
});

function Enrolled() {
  const classes = useStyles();
  const history = useHistory();

  const settings = useSelector((state) => state.settings.data);
  const enrollments = useSelector((state) => state.enrollments);
  const dispatch = useDispatch();

  const hasResendPermission = useHasPermission(
    "Resend Enrollment Confirmation"
  );

  const [filters, setFilters] = useState({
    search: "",
    limit: 15,
    course_id: "",
    level: "",
    school_year: settings.enrollment_school_year,
    semester: settings.enrollment_semester,
  });

  const [loading, setLoading] = useState(false);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [selectedWithdrawn, setSelectedWithdrawn] = useState("");
  const [sending, setSending] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [updateValues, setUpdateValues] = useState({});
  const [openShoftCourseModal, setOpenShiftCourseModal] = useState(false);
  const [id, setId] = useState("");
  const [selectedStudent, setSelectedStudent] = useState({});
  const [courses, setCourses] = useState([]);

  const columns = [
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value) => (
          <Fragment>
            <IconButton
              color="primary"
              size="medium"
              onClick={() => handleOpenEvaluateForm(value)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              size="medium"
              onClick={() => handlePrint(value)}
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              color="primary"
              size="medium"
              title="Shift Course"
              onClick={() => handleOpenShiftCourseModal(value)}
            >
              <SwapHorizontalCircleIcon />
            </IconButton>

            {hasResendPermission && (
              <IconButton
                color="primary"
                size="medium"
                title="Resend Email"
                onClick={() => handleSendEmail(value)}
              >
                {sending.includes(value) ? (
                  <CircularProgress size={24} />
                ) : (
                  <EmailIcon />
                )}
              </IconButton>
            )}

            <IconButton
              color="secondary"
              size="medium"
              title="Withdraw Account"
              onClick={() => handleWithdrawn(value)}
            >
              <DeleteForeverOutlined />
            </IconButton>
          </Fragment>
        ),
      },
    },
    {
      name: "student",
      label: "Last Name",
      options: {
        customBodyRender: (value) => (value && value.last_name) || "",
      },
    },
    {
      name: "student",
      label: "First Name",
      options: {
        customBodyRender: (value) => (value && value.first_name) || "",
      },
    },
    {
      name: "student",
      label: "Middle Name",
      options: {
        customBodyRender: (value) => (value && value.middle_name) || "",
      },
    },
    {
      name: "course",
      label: "Course",
      options: {
        customBodyRender: (value) => (value && value.code) || "",
      },
    },
    {
      name: "level",
      label: "Year/Level",
      options: {
        customBodyRender: (value) => getLevel(value),
      },
    },
    {
      name: "student_status",
      label: "Status",
      options: {
        customBodyRender: (value) =>
          value === "1" ? (
            <span style={{ color: palletes.success }}>Regular</span>
          ) : (
            <span style={{ color: palletes.error }}>Irregular</span>
          ),
      },
    },
    {
      name: "created_at",
      label: "Date",
    },
  ];

  useEffect(() => {
    const localFilter = JSON.parse(localStorage.getItem("enrollment-enrolled"));

    if (localFilter) {
      setFilters((prev) => ({
        ...prev,
        ...localFilter,
      }));
    }

    fetchingData(localFilter || {});
    service.getCourses().then((res) => {
      if (res.data) {
        setCourses(res.data.courses);
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleWithdrawn = (value) => {
    setOpenWithdrawModal(true);
    setSelectedWithdrawn(value);
  };

  const handleConfirmWithdrawn = () => {
    dispatch(service.withdrawAccount(selectedWithdrawn)).then((res) => {
      if (res) {
        setOpenWithdrawModal(false);
      }
    });
  };

  const handleSendEmail = (id) => {
    setSending([id]);
    service
      .resendConfirmationMail(id)
      .then((res) => {
        if (res.data.status) {
          dispatch(showNofications("Enrollment Confirmation Email Sent"));
        }
        setSending([]);
      })
      .catch((err) => {
        setSending([]);
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };

  const handleOpenShiftCourseModal = (id) => {
    setId(id);
    const admission = enrollments.items.filter(
      (emp) => emp.id === parseInt(id)
    );
    setSelectedStudent(admission[0] || {});
    setOpenShiftCourseModal(true);
  };

  const handleFilterChange = (name, value) => {
    if (name !== "limit") {
      fetchingData({
        ...filters,
        limit: 15,
        [name]: value,
      });
    }

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePrint = (id) => {
    const win = window.open(`/enrollments/enrolled/${id}/print`, "_blank");
    win.focus();
  };

  const handleChangePage = (page) => {
    fetchingData({ ...filters, page: page + 1 });
  };

  const handleChangeRowsPerPage = (limit) => {
    handleFilterChange("limit", limit);
    fetchingData({ ...filters, limit });
  };

  const fetchingData = (params = {}) => {
    const newFilter = {
      ...filters,
      enrolled: true,
      ...params,
    };

    localStorage.setItem("enrollment-enrolled", JSON.stringify(newFilter));

    dispatch(service.getAdmissions(newFilter));
  };

  const handleOpenEvaluateForm = async (id) => {
    let items = enrollments.items.filter((emp) => emp.id === parseInt(id));
    await setUpdateValues((items && items[0]) || {});
    setOpenModal(true);
  };

  const handleShiftCourse = (params) => {
    setLoading(true);
    service
      .shiftCourse(id, params)
      .then((res) => {
        if (res.data.data) {
          history.push(
            `/admin/enrollments/for-enrollments?search=${selectedStudent.student.last_name}`
          );
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "err"));
        setLoading(false);
      });
  };

  return (
    <Card>
      <Box className={classes.searchBox}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <FormSelectField
              label="School Year"
              options={schoolYearOptions()}
              value={filters.school_year}
              onChange={(e) =>
                handleFilterChange("school_year", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormSelectField
              label="Semester"
              keyValuePair="id,label"
              options={semesterOptions()}
              value={filters.semester}
              onChange={(e) => handleFilterChange("semester", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormSelectField
              label="Courses"
              keyValuePair="id,code"
              options={courses}
              value={filters.course_id}
              onChange={(e) => handleFilterChange("course_id", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormSelectField
              label="Year/Level"
              keyValuePair="id,label"
              options={levelOptions()}
              value={filters.level}
              onChange={(e) => handleFilterChange("level", e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <FormField
              label="Search"
              value={filters.search}
              fullWidth
              onChange={(e) => handleFilterChange("search", e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>

      {enrollments.fetching ? (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <BasicDataTable
          withPagination
          columns={columns}
          data={enrollments.items || 0}
          page={enrollments.meta.current_page - 1 || 0}
          count={enrollments.meta.total}
          rowsPerPage={filters.limit}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <ViewEnrollment
        open={openModal}
        onClose={() => setOpenModal(false)}
        values={updateValues}
        courses={courses}
      />

      <ShiftCourse
        open={openShoftCourseModal}
        onClose={() => setOpenShiftCourseModal(false)}
        courses={courses}
        selectedStudent={selectedStudent}
        handleConfirm={handleShiftCourse}
        loading={loading}
      />

      <PromptModal
        message="Are you sure you want to withdraw this enrollment?"
        open={openWithdrawModal}
        onClose={() => setOpenWithdrawModal(false)}
        handleConfirm={handleConfirmWithdrawn}
        loading={enrollments.withdrawing}
      />
    </Card>
  );
}

export default Enrolled;
