import { Box, Card, CardContent, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SettingsTab from '../components/SettingsTab';
import settingsService from 'services/SettingService';
import BasicDataTable from 'components/BasicDataTable';
import Edit from '@material-ui/icons/Edit';
import EditPaymentSettings from '../components/EditPaymentSettings';

const types = [
  {
    name: 'minimum_midterm_payment',
    label: 'Minimum Midterm Payment',
  },
  {
    name: 'minimum_final_payment',
    label: 'Minimum Final Payment',
  },
];

function Payments() {
  const [settings, setSettings] = useState([]);
  const [selected, setSelected] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);

  const toggleEditModal = () => setShowEditModal((prev) => !prev);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    settingsService
      .getSetting({ type: 'minimum_midterm_payment,minimum_final_payment' })
      .then((res) => {
        if (res.data) {
          setSettings(res.data);
        }
      });
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRender: (value, item) => (
          <IconButton size="small" onClick={() => handleEdit(item)}>
            <Edit color="primary" />
          </IconButton>
        ),
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: (value) => {
          const item = types.filter((val) => val.name === value)[0] || {};

          return item.label || '';
        },
      },
    },
    {
      name: 'value',
      label: 'Amount',
    },
    {
      name: 'scope',
      label: 'Scope',
    },
  ];

  const handleEdit = (item) => {
    setSelected(item);
    toggleEditModal();
  };

  return (
    <Box>
      <SettingsTab />
      <Card>
        <CardContent>
          <BasicDataTable data={settings} columns={columns} />
        </CardContent>
      </Card>

      <EditPaymentSettings
        refresh={fetchData}
        open={showEditModal}
        onClose={toggleEditModal}
        values={selected}
      />
    </Box>
  );
}

export default Payments;
