export const REQUEST_FETCH_SUBJECTOFFERS = "REQUEST_FETCH_SUBJECTOFFERS";
export const FETCH_SUBJECTOFFERS = "FETCH_SUBJECTOFFERS";
export const FAIL_FETCH_SUBJECTOFFERS = "FAIL_FETCH_SUBJECTOFFERS";

export const REQUEST_CREATE_SUBJECTOFFER = "REQUEST_CREATE_SUBJECTOFFER";
export const CREATE_SUBJECTOFFER = "CREATE_SUBJECTOFFER";
export const FAIL_CREATE_SUBJECTOFFER = "FAIL_CREATE_SUBJECTOFFER";

export const REQUEST_UPDATE_SUBJECTOFFER = "REQUEST_UPDATE_SUBJECTOFFER";
export const UPDATE_SUBJECTOFFER = "UPDATE_SUBJECTOFFER";
export const FAIL_UPDATE_SUBJECTOFFER = "FAIL_UPDATE_SUBJECTOFFER";

export const REQUEST_UPDATE_OFFER = "REQUEST_UPDATE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const FAIL_UPDATE_OFFER = "FAIL_UPDATE_OFFER";
