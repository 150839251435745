import * as types from "./actionTypes";

// fetching pending admissions
export function requestFetchPendingAdmissions() {
  return {
    type: types.REQUEST_FETCH_PENDING_ADMISSIONS,
  };
}

export function fetchPendingAdmissions(data) {
  return {
    type: types.FETCH_PENDING_ADMISSIONS,
    payload: data,
  };
}

export function failFetchPendingAdmissions(data) {
  return {
    type: types.FAIL_FETCH_PENDING_ADMISSIONS,
    payload: data,
  };
}

// enroll pending admissions
export function requestEnrollPendingAdmissions() {
  return {
    type: types.REQUEST_ENROLL_PENDING_ADMISSIONS,
  };
}

export function enrollPendingAdmissions(data) {
  return {
    type: types.ENROLL_PENDING_ADMISSIONS,
    payload: data,
  };
}

export function failEnrollPendingAdmissions(data) {
  return {
    type: types.FAIL_ENROLL_PENDING_ADMISSIONS,
    payload: data,
  };
}

// reject pending admissions
export function requestRejectPendingAdmissions() {
  return {
    type: types.REQUEST_REJECT_PENDING_ADMISSIONS,
  };
}

export function rejectPendingAdmissions(data) {
  return {
    type: types.REJECT_PENDING_ADMISSIONS,
    payload: data,
  };
}

export function failRejectPendingAdmissions(data) {
  return {
    type: types.FAIL_REJECT_PENDING_ADMISSIONS,
    payload: data,
  };
}

// accept enrollment
export function requestAcceptEnrollments() {
  return {
    type: types.REQUEST_ACCEPT_ENROLEMENT,
  };
}

export function acceptEnrollments(data) {
  return {
    type: types.ACCEPT_ENROLEMENT,
    payload: data,
  };
}

export function failAcceptEnrollments(data) {
  return {
    type: types.FAIL_ACCEPT_ENROLEMENT,
    payload: data,
  };
}

// update enrollment
export function requestUpdateEnrollments() {
  return {
    type: types.REQUEST_UPDATE_ENROLEMENT,
  };
}

export function updateEnrollments(data) {
  return {
    type: types.UPDATE_ENROLEMENT,
    payload: data,
  };
}

export function failUpdateEnrollments(data) {
  return {
    type: types.FAIL_UPDATE_ENROLEMENT,
    payload: data,
  };
}

// remove enroll subject
export function requestRemoveEnrolledSubject() {
  return {
    type: types.REQUEST_REMOVE_ENROLLED_SUBJECT,
  };
}

export function removeEnrolledSubject(data) {
  return {
    type: types.REMOVE_ENROLLED_SUBJECT,
    payload: data,
  };
}

export function failRemoveEnrolledSubject(data) {
  return {
    type: types.FAIL_REMOVE_ENROLLED_SUBJECT,
    payload: data,
  };
}

export function updateEnrollment(data) {
  return {
    type: types.UPDATE_ENROLEMENT,
    payload: data,
  };
}

export function deleteEnrollment(data) {
  return {
    type: types.DELETE_ENROLEMENT,
    payload: data,
  };
}

export function failWithdrawAccount(data) {
  return {
    type: types.FAILED_WITHDRAWN_ACCOUNT,
    payload: data,
  };
}

export function withdrawAccount(data) {
  return {
    type: types.WITHDRAWN_ACCOUNT,
    payload: data,
  };
}

export function requestWithdrawAccount(data) {
  return {
    type: types.REQUEST_WITHDRAWN_ACCOUNT,
    payload: data,
  };
}
