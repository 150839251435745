import { Button } from "@material-ui/core";
import React from "react";

function CustomButton(props) {
  const { children, ...rest } = props;
  return (
    <Button size="small" variant="contained" color="primary" {...rest}>
      {children}
    </Button>
  );
}

export default CustomButton;
