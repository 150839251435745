export const REQUEST_FETCH_COURSES = "REQUEST_FETCH_COURSES";
export const FETCH_COURSES = "FETCH_COURSES";
export const FAIL_FETCH_COURSES = "FAIL_FETCH_COURSES";

export const REQUEST_CREATE_COURSE = "REQUEST_CREATE_COURSE";
export const CREATE_COURSE = "CREATE_COURSE";
export const FAIL_CREATE_COURSE = "FAIL_CREATE_COURSE";

export const REQUEST_UPDATE_COURSE = "REQUEST_UPDATE_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const FAIL_UPDATE_COURSE = "FAIL_UPDATE_COURSE";
