import { CardContent } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Card } from "@material-ui/core";
import BasicDataTable from "../../../../components/BasicDataTable";
import React, { useEffect, useState } from "react";
import * as service from "../service";

import FormField from "../../../../components/FormField";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

function Logs() {
  const [loading, setLoading] = useState(false);
  const [resetLogs, setResetLogs] = useState({
    data: [],
    meta: {},
  });

  const [filters, setFilters] = useState({
    limit: 15,
    search: "",
  });

  const columns = [
    {
      name: "user.name",
      label: "Name",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "or_number",
      label: "OR Number",
    },
    {
      name: "password",
      label: "Temp Password",
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        customBodyRender: (value) => moment(value).format("YYYY-MM-DD"),
      },
    },
  ];

  useEffect(() => {
    fetchingData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchingData = (params = {}) => {
    setLoading(true);
    service
      .getPasswordLogs({ ...filters, ...params })
      .then((res) => {
        if (res.data) {
          setResetLogs(res.data);
        }

        setLoading(false);
      })
      .catch(() => {
        if (!axios.isCancel) {
          setLoading(false);
        }
      });
  };

  const handleFilterChange = (name, value) => {
    if (name === "search") {
      fetchingData({ search: value });
    }

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangePage = (page) => {
    fetchingData({ page: page + 1 });
  };

  const handleChangeRowsPerPage = (limit) => {
    handleFilterChange("limit", limit);
    fetchingData({ limit });
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Box>
            <FormField
              label="Search"
              onChange={(e) => handleFilterChange("search", e.target.value)}
              value={filters.search}
            />
          </Box>
          <Box>
            {loading ? (
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            ) : (
              <BasicDataTable
                withPagination
                withIndex
                columns={columns}
                data={resetLogs.data || []}
                page={resetLogs.meta.current_page - 1}
                count={resetLogs.meta.total || 0}
                rowsPerPage={filters.limit}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default Logs;
