export const REQUEST_FETCH_PENDING_ADMISSIONS =
  "REQUEST_FETCH_PENDING_ADMISSIONS";
export const FETCH_PENDING_ADMISSIONS = "FETCH_PENDING_ADMISSIONS";
export const FAIL_FETCH_PENDING_ADMISSIONS = "FAIL_FETCH_PENDING_ADMISSIONS";

export const REQUEST_ENROLL_PENDING_ADMISSIONS =
  "REQUEST_ENROLL_PENDING_ADMISSIONS";
export const ENROLL_PENDING_ADMISSIONS = "ENROLL_PENDING_ADMISSIONS";
export const FAIL_ENROLL_PENDING_ADMISSIONS = "FAIL_ENROLL_PENDING_ADMISSIONS";

export const REQUEST_REJECT_PENDING_ADMISSIONS =
  "REQUEST_REJECT_PENDING_ADMISSIONS";
export const REJECT_PENDING_ADMISSIONS = "REJECT_PENDING_ADMISSIONS";
export const FAIL_REJECT_PENDING_ADMISSIONS = "FAIL_REJECT_PENDING_ADMISSIONS";

export const REQUEST_ACCEPT_ENROLEMENT = "REQUEST_ACCEPT_ENROLEMENT";
export const ACCEPT_ENROLEMENT = "ACCEPT_ENROLEMENT";
export const FAIL_ACCEPT_ENROLEMENT = "FAIL_ACCEPT_ENROLEMENT";

export const REQUEST_UPDATE_ENROLEMENT = "REQUEST_UPDATE_ENROLEMENT";
export const UPDATE_ENROLEMENT = "UPDATE_ENROLEMENT";
export const FAIL_UPDATE_ENROLEMENT = "FAIL_UPDATE_ENROLEMENT";

export const REQUEST_REMOVE_ENROLLED_SUBJECT =
  "REQUEST_REMOVE_ENROLLED_SUBJECT";
export const REMOVE_ENROLLED_SUBJECT = "REMOVE_ENROLLED_SUBJECT";
export const FAIL_REMOVE_ENROLLED_SUBJECT = "FAIL_REMOVE_ENROLLED_SUBJECT";

export const DELETE_ENROLEMENT = "DELETE_ENROLEMENT";

export const REQUEST_WITHDRAWN_ACCOUNT = "REQUEST_WITHDRAWN_ACCOUNT";
export const WITHDRAWN_ACCOUNT = "WITHDRAWN_ACCOUNT";
export const FAILED_WITHDRAWN_ACCOUNT = "FAILED_WITHDRAWN_ACCOUNT";
