const { createMuiTheme } = require("@material-ui/core");

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        "&:hover": {
          backgroundColor: "#4e73df",
        },
      },
    },
    MuiDialogTitle: {
      root: {
        "@media (max-width: 700px)": {
          padding: "8px 16px",
        },
      },
    },
    MuiDialogContent: {
      root: {
        "@media (max-width: 700px)": {
          padding: "8px 16px",
        },
      },
    },
    MuiTypography: {
      h6: {
        "@media (max-width: 500px)": {
          fontSize: "16px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#4e73df",
    },
  },
  typography: {
    fontFamily: ["'Poppins'", "sans-serif"].join(","),
  },
});

export default theme;
