import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Logo from "../assets/img/MLG_Logo.png";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    width: 80,
  },
});

function MaintenanceMode() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <img alt="logo" className={classes.image} src={Logo} />
        <Typography color="error">Portal is under maintenance</Typography>
      </div>
    </div>
  );
}

export default MaintenanceMode;
