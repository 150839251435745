import Http from 'utils/Http';

class SettingService {
  getSetting(params) {
    return Http.get(`/api/settings/single`, { params });
  }

  update(id, params) {
    return Http.post(`/api/settings/${id}`, params);
  }
}

const settingsService = new SettingService();

export default settingsService;
