import React from 'react';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';

import './assets/css/material-dashboard-react.css?v=1.0.0';
import 'font-awesome/css/font-awesome.min.css';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import app from 'utils/app';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={app.google_client_id}>
        <App />
      </GoogleOAuthProvider>
    </ThemeProvider>
  </Provider>
);
