import React, { useEffect, useState } from "react";
import FormField from "../../../../components/FormField";
import FormDialog from "../../../../components/FormDialog";
import { getValuesFromObject, Revalidate } from "../../../../utils/helpers";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const validator = Revalidate({
  first_name: "required",
  last_name: "required",
  middle_name: "",
  personal_email: "required|email",
  contact_number: "required|integer|max:11|min:11",
  email: "required|email",
  role: "required",
});

function UpdateForm(props) {
  const {
    open,
    onClose,
    allRoles = [],
    loading,
    handleUpdate,
    values = {},
  } = props;

  const user = useSelector((state) => state.user.data);

  const [roles, setRoles] = useState([]);
  const [formValues, setFormValues] = useState({
    values: {
      first_name: "",
      last_name: "",
      middle_name: "",
      personal_email: "",
      contact_number: "",
      email: "",
    },
    errors: validator.errors,
  });

  useEffect(() => {
    if (open) {
      setFormValues((prev) => ({
        ...prev,
        values: {
          first_name: getValuesFromObject(values, "info.first_name"),
          last_name: getValuesFromObject(values, "info.last_name"),
          middle_name: getValuesFromObject(values, "info.middle_name"),
          personal_email: getValuesFromObject(values, "info.personal_email"),
          email: getValuesFromObject(values, "email"),
          contact_number: getValuesFromObject(values, "info.contact_number"),
        },
      }));

      setRoles(values.roles);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCheckRoles = (name) => {
    if (roles.includes(name)) {
      setRoles((prev) => prev.filter((rl) => rl !== name));
    } else {
      setRoles((prev) => [...prev, name]);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { errors } = validator;

    errors.remove(name);

    setFormValues((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: value,
      },
    }));

    validator
      .validate(name, value)
      .then(() =>
        setFormValues((prev) => ({ ...prev, errors: validator.errors }))
      );
  };

  const handleSubmit = () => {
    validator.validateAll(formValues.values).then((success) => {
      if (success) {
        handleUpdate({
          ...formValues.values,
          roles,
        });
      } else {
        setFormValues((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  return (
    <FormDialog open={open} onClose={onClose}>
      <DialogTitle>Update User</DialogTitle>
      <DialogContent>
        <Divider />
        <FormField
          name="email"
          label="MLGCL Email"
          type="email"
          value={formValues.values.email}
          onChange={handleChange}
          errors={formValues.errors}
        />

        <FormField
          name="first_name"
          label="First Name"
          value={formValues.values.first_name}
          onChange={handleChange}
          errors={formValues.errors}
        />
        <FormField
          name="last_name"
          label="Last Name"
          value={formValues.values.last_name}
          onChange={handleChange}
          errors={formValues.errors}
        />
        <FormField
          name="middle_name"
          label="Middle Name"
          onChange={handleChange}
          value={formValues.values.middle_name}
          errors={formValues.errors}
        />
        <FormField
          name="personal_email"
          label="Personal Email"
          type="email"
          value={formValues.values.personal_email}
          onChange={handleChange}
          errors={formValues.errors}
        />

        <FormField
          name="contact_number"
          label="Contact Number"
          value={formValues.values.contact_number}
          onChange={handleChange}
          errors={formValues.errors}
        />

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Roles</Typography>
          </Grid>
          {allRoles
            .filter((role) => user.is_admin || role !== "Student")
            .map((role, index) => (
              <Grid item xs={4} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={roles.includes(role)}
                      onChange={() => handleCheckRoles(role)}
                      name={role}
                      color="primary"
                    />
                  }
                  label={role}
                />
              </Grid>
            ))}
        </Grid>

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Update"}
          </Button>
        </div>
      </DialogContent>
    </FormDialog>
  );
}

UpdateForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  allRoles: PropTypes.array,
  loading: PropTypes.bool,
  handleUpdate: PropTypes.func,
  values: PropTypes.object,
};

export default UpdateForm;
