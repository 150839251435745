import Http from "../../../utils/Http";
import axios from "axios";

const CancelToken = axios.CancelToken;
let cancel;

export const getAllUsers = (params = {}) => {
  if (cancel) cancel();

  return Http.get("/api/users", {
    params,
    cancelToken: new CancelToken((c) => (cancel = c)),
  });
};

export const getPasswordLogs = (params = {}) => {
  if (cancel) cancel();

  return Http.get("/api/password-reset-logs", {
    params,
    cancelToken: new CancelToken((c) => (cancel = c)),
  });
};

export const resetPassword = (id, params = {}) => {
  return Http.post(`/api/password-reset-logs/${id}`, { ...params });
};
