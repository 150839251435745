import Http from "../../utils/Http";
import axios from "axios";

const CancelToken = axios.CancelToken;
let cancel;

export const getSubjectLoads = (params) => {
  return Http.get(`/api/employee/subjectLoads`, { params });
};

export const getSubjectRoster = (id) => {
  return Http.get(`/api/employee/subjectRoster/${id}`);
};

export const getAllStudentBySubject = (id, params = {}) => {
  if (cancel) cancel();

  return Http.get(`/api/employee/allStudentBySubject/${id}`, {
    params,
    cancelToken: new CancelToken((c) => (cancel = c)),
  });
};

export const getStudentGradeBySubject = (id, params = {}) => {
  if (cancel) cancel();

  return Http.get(`/api/employee/studentGradeBySubject/${id}`, {
    params,
    cancelToken: new CancelToken((c) => (cancel = c)),
  });
};

export const updateStudentGrades = (params) => {
  return Http.post("/api/employee/updateStudentGrades", { ...params });
};

export const exportSubjectRoster = (id) => {
  return Http.get(`/api/employee/subjectRoster/export/${id}`, {
    responseType: "blob",
  });
};
