import React from "react";
import { useEffect } from "react";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { setMaintenance } from "./store/actionCreators";
import MaintenanceMode from "./MaintenanceMode";
import PropTypes from "prop-types";
import { handleResponseError, systemRoles } from "../utils/helpers";
import { showNofications } from "../modules/layouts/store/actionCreators";

function Authenticate({ children }) {
  const dispatch = useDispatch();
  const maintenance = useSelector((state) => state.maintenanceInfo.maintenance);
  const user = useSelector((state) => state.user.data);

  const isAdmin = () => {
    return (
      (user.roles && user.roles.includes(systemRoles.administrator)) || false
    );
  };

  const disableMaintenance =
    window.location.search.includes("maintenance") || isAdmin();

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("maintenance"));

    if (localData) {
      dispatch(setMaintenance(localData.maintenance));
    }

    service
      .getMaintenance()
      .then((res) => {
        if (res.data) {
          localStorage.setItem("maintenance", JSON.stringify(res.data));
          dispatch(setMaintenance(res.data.maintenance));
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return !disableMaintenance && maintenance ? <MaintenanceMode /> : children;
}

Authenticate.propTypes = {
  children: PropTypes.node,
};

export default Authenticate;
