import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormDialog from "../../../../components/FormDialog";
import NoImage from "../../../../assets/img/no_image.png";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import palletes from "../../../../assets/styles/palletes";
import SubjectOfferModal from "./SubjectOfferModal";
import RemoveEnrolledSubjectModal from "./RemoveEnrolledSubjectModal";
import * as service from "../service";

import PropTypes from "prop-types";
import { showNofications } from "../../../layouts/store/actionCreators";
import { handleResponseError } from "../../../../utils/helpers";

const useStyles = makeStyles({
  photoBox: {
    textAlign: "center",
  },
  photo: {
    width: "100%",
    borderRadius: 5,
    "@media (max-width: 959px)": {
      maxWidth: 250,
      margin: "0 auto",
    },
  },
  photoText: {
    color: "#fff",
    backgroundColor: "#e3342f",
    padding: 6,
  },
  heading: {
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    color: "#e3342f",
    paddingLeft: 8,
  },
  buttonBox: {
    textAlign: "right",
    "@media (max-width: 550px)": {
      textAlign: "center",
    },
  },
  delete: {
    color: palletes.error,
  },
  add: {
    color: palletes.success,
  },
  button: {
    paddingLeft: 40,
    paddingRight: 40,
    margin: 5,
  },
  buttonReject: {
    backgroundColor: "#e3342f",
    "&:hover": {
      backgroundColor: "#e3342f",
    },
  },
  buttonSuccess: {
    backgroundColor: "#38c172",
    "&:hover": {
      backgroundColor: "#38c172",
    },
  },
  enrollSubjectText: {
    textAlign: "center",
    paddingTop: 6,
    paddingBottom: 6,
    backgroundColor: palletes.success,
    color: palletes.white,
  },
  label: {
    display: "flex",
    "& span:first-child": {
      flexBasis: 150,
      "@media (max-width: 500px)": {
        flexBasis: "unset",
      },
    },
    "& span:last-child": {
      "@media (max-width: 500px)": {
        paddingLeft: 30,
      },
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
});

function ViewEnrollment({ open, onClose, values, courses = [] }) {
  const classes = useStyles();

  const enrollments = useSelector((state) => state.enrollments);
  const dispatch = useDispatch();

  const [subjectLoads, setSubjectLoads] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [removeId, setRemoveId] = useState("");

  useEffect(() => {
    if (open && values.id) {
      setSubjectLoads([]);
      fetchSubjects();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, values.id]);

  const fetchSubjects = () => {
    setLoading(true);
    service
      .getEnrolledSubjects(values.id)
      .then((res) => {
        if (res.data.data) {
          setSubjectLoads(res.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };

  const getName = () => {
    return (values && values.student && values.student.full_name) || "";
  };

  const getAddress = () => {
    return (values && values.student && values.student.full_address) || "";
  };

  const handleRemove = async (id) => {
    await setRemoveId(id);
    setOpenConfirmModal(true);
  };

  const handleConfirmRemove = () => {
    dispatch(service.removeEnrolledSubject(values.id, { load: removeId })).then(
      (data) => {
        if (data) {
          setSubjectLoads(
            subjectLoads.filter((sub) => sub.id !== parseInt(removeId))
          );
          setOpenConfirmModal(false);
        }
      }
    );
  };

  const getTotalUnits = () => {
    return subjectLoads
      .map(
        (sub) =>
          (sub.subjectOffer &&
            sub.subjectOffer.prospectus &&
            parseInt(sub.subjectOffer.prospectus.units)) ||
          0
      )
      .reduce((a, b) => a + b, 0);
  };

  return (
    <FormDialog open={open} onClose={onClose} maxWidth="xl">
      <DialogTitle>
        Information of
        <span className={classes.title}>{getName()}</span>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Box padding={1} className={classes.photoBox}>
              {values && values.image ? (
                <img
                  className={classes.photo}
                  alt="profile pic"
                  src={values.image}
                />
              ) : (
                <img
                  className={classes.photo}
                  alt="profile pic"
                  src={NoImage}
                />
              )}
              <Typography className={classes.photoText} align="center">
                <span
                  className={classes.photoText}
                >{`Applicant's Selfie`}</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box padding={2}>
              <Typography className={classes.heading}>
                Personal Information
              </Typography>
              <Divider />
              <Typography className={classes.label}>
                <span>Course:</span>
                <span>
                  {(values && values.course && values.course.code) || "----"}
                </span>
              </Typography>
              <Typography className={classes.label}>
                <span>Year:</span>
                <span>{(values && values.level) || "----"}</span>
              </Typography>
              <Typography className={classes.label}>
                <span>School ID:</span>
                <span>
                  {values && values.student && values.student.student_id_number}
                </span>
              </Typography>
              <Typography className={classes.label}>
                <span>Name:</span> <span>{getName()}</span>
              </Typography>
              <Typography className={classes.label}>
                <span>Contact:</span>
                <span>
                  {values && values.student && values.student.contact_number}
                </span>
              </Typography>
              <Typography className={classes.label}>
                <span>Gender:</span>
                <span>{values && values.student && values.student.gender}</span>
              </Typography>
              <Typography className={classes.label}>
                <span>Civil Status:</span>
                <span>
                  {values && values.student && values.student.civil_status}
                </span>
              </Typography>
              <Typography className={classes.label}>
                <span>Religion:</span>
                <span>
                  {values && values.student && values.student.religion}
                </span>
              </Typography>
              <Typography className={classes.label}>
                <span>Address:</span>
                <span>{getAddress()}</span>
              </Typography>
            </Box>
          </Grid>

          <Grid item container justify="flex-end" alignItems="center" xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              Add Subject
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Box padding={2}>
              <Typography className={classes.enrollSubjectText}>
                Added Subjects
              </Typography>
              <Divider />
              {loading && (
                <Grid container justify="center">
                  <CircularProgress />
                </Grid>
              )}

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Curriculum</TableCell>
                    <TableCell>Instructor</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell>Section</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subjectLoads.map((sub, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {(sub.subjectOffer &&
                          sub.subjectOffer.prospectus &&
                          sub.subjectOffer.prospectus.code) ||
                          ""}
                      </TableCell>
                      <TableCell>
                        {(sub.subjectOffer &&
                          sub.subjectOffer.prospectus &&
                          sub.subjectOffer.prospectus.descriptive_title) ||
                          ""}
                      </TableCell>
                      <TableCell>
                        {(sub.subjectOffer &&
                          sub.subjectOffer.prospectus &&
                          sub.subjectOffer.prospectus.curricula &&
                          sub.subjectOffer.prospectus.curricula.name) ||
                          ""}
                      </TableCell>
                      <TableCell>
                        {(sub.subjectOffer &&
                          sub.subjectOffer.employee &&
                          sub.subjectOffer.employee.partial_name) ||
                          ""}
                      </TableCell>
                      <TableCell>
                        {(sub.subjectOffer &&
                          sub.subjectOffer.prospectus &&
                          sub.subjectOffer.prospectus.type) ||
                          ""}
                      </TableCell>
                      <TableCell>
                        {(sub.subjectOffer &&
                          sub.subjectOffer.prospectus &&
                          sub.subjectOffer.prospectus.units) ||
                          ""}
                      </TableCell>
                      <TableCell>
                        {(sub.subjectOffer && sub.subjectOffer.section) || ""}
                      </TableCell>

                      <TableCell>
                        <IconButton
                          color="primary"
                          edge="end"
                          onClick={() => handleRemove(sub.id)}
                        >
                          <HighlightOffIcon className={classes.delete} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography align="right">
                Total Units: {getTotalUnits()}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.buttonBox}>
              <Button
                className={`${classes.button} ${classes.buttonReject} buttonReject`}
                size="small"
                variant="contained"
                color="primary"
                disabled={enrollments.accepting}
                onClick={onClose}
              >
                <HighlightOffIcon /> Close
              </Button>
            </Box>
          </Grid>
        </Grid>

        <SubjectOfferModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          courses={courses}
          values={values}
          loads={subjectLoads}
          setSubjectLoads={setSubjectLoads}
        />

        <RemoveEnrolledSubjectModal
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          handleRemove={handleConfirmRemove}
          loading={enrollments.removing}
        />
      </DialogContent>
      <DialogActions style={{ display: "block" }}>
        {enrollments.accepting && (
          <Box>
            <LinearProgress style={{ height: 6 }} />
          </Box>
        )}
      </DialogActions>
    </FormDialog>
  );
}

ViewEnrollment.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  values: PropTypes.object,
  courses: PropTypes.array,
};

export default ViewEnrollment;
