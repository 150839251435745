import { DialogContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import FormDialog from "../../../../components/FormDialog";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormField from "../../../../components/FormField";
import FormSelectField from "../../../../components/FormSelectField";
import generator from "generate-password";
import { Revalidate } from "../../../../utils/helpers";

const validator = Revalidate({
  type: "required",
  or_number: "required",
  password: "",
});

function ResetModal({ handleConfirm, loading, ...rest }) {
  const [formValues, setFormValues] = useState({
    values: {
      type: "Portal",
      or_number: "",
      password: "",
    },
    errors: validator.errors,
  });

  useEffect(() => {
    if (rest.open) {
      setFormValues({
        values: {
          type: "Portal",
          or_number: "",
          password: "",
        },
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.open]);

  const handleChange = (name, value) => {
    const newValues = {
      [name]: value,
    };

    const { errors } = validator;

    if (name === "type") {
      newValues.password = generator.generate({
        length: 12,
        numbers: true,
        uppercase: true,
        lowercase: true,
      });
    }

    errors.remove(name);

    setFormValues((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...newValues,
      },
    }));

    validator.validate(name, value).then(() =>
      setFormValues((prev) => ({
        ...prev,
        errors: validator.errors,
      }))
    );
  };

  const handleValidate = () => {
    validator.validateAll(formValues.values).then((success) => {
      if (success && handleConfirm) {
        handleConfirm(formValues.values);
      } else {
        setFormValues((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  return (
    <FormDialog {...rest}>
      <DialogTitle>
        <Typography color="error">
          Are you sure you want to reset this user password?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormSelectField
          label="Type"
          value={formValues.values.type}
          onChange={(e) => handleChange("type", e.target.value)}
          options={["Portal", "Email"]}
          name="type"
          errors={formValues.errors}
        />

        <FormField
          label="OR Number"
          value={formValues.values.or_number}
          name="or_number"
          onChange={(e) => handleChange("or_number", e.target.value)}
          errors={formValues.errors}
        />
        {formValues.values.type === "Email" && (
          <FormField
            label="Password {optional)"
            value={formValues.values.password}
            onChange={(e) => handleChange("password", e.target.value)}
            name="password"
            errors={formValues.errors}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className="buttonSuccess"
          disabled={loading}
          onClick={handleValidate}
        >
          {loading ? <CircularProgress /> : "Confirm"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="buttonReject"
          disabled={loading}
          onClick={() => rest.onClose()}
        >
          Close
        </Button>
      </DialogActions>
    </FormDialog>
  );
}

ResetModal.propTypes = {
  loading: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

export default ResetModal;
