import { Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

function RegistrarTab() {
  const location = useLocation();
  const history = useHistory();
  const [currentTab] = useState(location.pathname);

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  const tabList = [
    {
      label: 'Courses',
      path: '/admin/settings/registrar/courses',
    },
    {
      label: 'Prospectus',
      path: '/admin/settings/registrar/prospectus',
    },
    {
      label: 'Subject Offer',
      path: '/admin/settings/registrar/subject-offer',
    },
    {
      label: 'Subject Categories',
      path: '/admin/settings/registrar/subject-categories',
    },
  ];

  return (
    <Tabs
      value={currentTab}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="disabled tabs example"
    >
      {tabList.map((val, index) => (
        <Tab
          key={index}
          label={val.label}
          value={val.path}
          style={{ backgroundColor: val.path === location.pathname ? '#fff' : 'unset' }}
        />
      ))}
    </Tabs>
  );
}

export default RegistrarTab;
