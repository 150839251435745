import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import PropTypes from "prop-types";
import {
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  closeBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FormDialog(props) {
  const {
    open,
    onClose,
    children,
    title,
    disableOutsideClick = false,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      fullWidth
      {...(!disableOutsideClick ? { onClose } : {})}
      maxWidth="sm"
      {...rest}
    >
      {title && (
        <DialogTitle disableTypography className={classes.closeBox}>
          <Typography>{title}</Typography>
          <IconButton color="secondary" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

FormDialog.defaultProps = {
  disableOutsideClick: false,
  open: false,
};

export default FormDialog;
