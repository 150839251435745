import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  DialogContent,
  DialogTitle,
  Checkbox,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormDialog from "../../../../components/FormDialog";
import { useDispatch, useSelector } from "react-redux";
import * as service from "../service";
import { showNofications } from "../../../layouts/store/actionCreators";
import { handleResponseError } from "../../../../utils/helpers";
import FormSelectField from "../../../../components/FormSelectField";
import FormField from "../../../../components/FormField";
import palletes from "../../../../assets/styles/palletes";

import PropTypes from "prop-types";

const useStyles = makeStyles({
  full: {
    backgroundColor: "#fb8b8b",
  },
});

function SubjectOfferModal({
  open,
  onClose,
  values,
  courses = [],
  loads = [],
  setSubjectLoads,
}) {
  const classes = useStyles();
  const enrollments = useSelector((state) => state.enrollments);
  const settings = useSelector((state) => state.settings.data);

  const [subjectOffers, setSubjectOffers] = useState({});
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    course_id: "",
    level: "",
    limit: 25,
    subject_code: "",
  });

  const added = loads.map((load) => load.subjectOffer && load.subjectOffer.id);

  useEffect(() => {
    if (open) {
      fetchingData({
        course_id: (values && values.course && values.course.id) || "",
        level: (values && values.level) || "",
        semester: (values && values.semester) || "",
      });
    } // eslint-disable-next-line
  }, [open, values]);

  useEffect(() => {
    if (open) {
      setSelected([]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getLevelOptions = () => {
    const levels = [
      {
        id: "1",
        name: "First Year",
      },
      {
        id: "2",
        name: "Second Year",
      },
      {
        id: "3",
        name: "Third Year",
      },
      {
        id: "4",
        name: "Fourth Year",
      },
    ];

    return levels;
  };

  const handleSelectedSubject = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((sel) => sel !== parseInt(id)));
    } else {
      setSelected([...selected, id]);
    }
  };

  const clearFilters = () => {
    const newFilters = {};
    for (const key in filters) {
      newFilters[key] = "";
    }

    setFilters(newFilters);
    fetchingData(newFilters);
  };

  const handleApplyFilters = () => {
    fetchingData();
  };

  const fetchingData = (params = {}) => {
    setLoading(true);
    service
      .getOfferSubjects({
        school_year: settings.enrollment_school_year,
        semester: settings.enrollment_semester,
        ...filters,
        ...params,
      })
      .then((res) => {
        if (res.data) {
          setSubjectOffers(res.data || {});
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeRowsPerPage = (e) => {
    const value = e.target.value;
    service
      .getOfferSubjects({ ...filters, limit: parseInt(value) })
      .then((res) => {
        if (res.data) {
          setSubjectOffers(res.data || {});
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };

  const handleChangePage = (e, newPage) => {
    setLoading(true);
    service
      .getOfferSubjects({ ...filters, page: newPage + 1 })
      .then((res) => {
        if (res.data) {
          setSubjectOffers(res.data || {});
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };

  const handleSubmit = () => {
    dispatch(
      service.updateEnrollments(values.id, {
        subjects: selected,
        student_status: values.student_status,
      })
    ).then((data) => {
      if (data) {
        setSubjectLoads(data.loads);
        onClose();
      }
    });
  };

  return (
    <FormDialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        Add Subject (Enrollment SY: {settings.enrollment_school_year} Sem:{" "}
        {settings.enrollment_semester})
      </DialogTitle>
      <DialogContent>
        <Typography
          color="error"
          style={{ backgroundColor: palletes.error, color: "#fff", padding: 6 }}
        >
          Already Added Subject will not show on the list.
        </Typography>
        <Box>
          <Box>
            <Typography>Filters</Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormSelectField
                  name="course_id"
                  label="Courses"
                  keyValuePair="id,code"
                  options={courses}
                  value={filters.course_id}
                  onChange={handleFilterChange}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormSelectField
                  label="Level"
                  name="level"
                  value={filters.level}
                  options={getLevelOptions()}
                  keyValuePair="id,name"
                  onChange={handleFilterChange}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FormField
                  name="subject_code"
                  label="Subject Code"
                  value={filters.subject_code}
                  onChange={handleFilterChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 4 }}
                  onClick={handleApplyFilters}
                >
                  Apply Filter
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={clearFilters}
                >
                  Clear Filter
                </Button>
              </Grid>
            </Grid>
          </Box>
          {loading ? (
            <Grid container justify="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Curriculum</TableCell>
                    <TableCell>Instructor</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Units</TableCell>
                    <TableCell>Section</TableCell>
                    <TableCell>Total Student(s)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(subjectOffers.data || [])
                    .filter((sub) => !added.includes(sub.id))
                    .map((sub, i) => (
                      <TableRow
                        key={i}
                        className={sub.is_full ? classes.full : ""}
                      >
                        <TableCell>
                          {sub.is_full ? (
                            "Full"
                          ) : (
                            <Checkbox
                              checked={selected.includes(sub.id)}
                              onChange={() => handleSelectedSubject(sub.id)}
                              color="primary"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {sub.prospectus && sub.prospectus.code}
                        </TableCell>
                        <TableCell>
                          {sub.prospectus && sub.prospectus.descriptive_title}
                        </TableCell>
                        <TableCell>
                          {(sub.prospectus &&
                            sub.prospectus.curricula &&
                            sub.prospectus.curricula.name) ||
                            ""}
                        </TableCell>
                        <TableCell>
                          {sub.prospectus && sub.employee_id}
                        </TableCell>
                        <TableCell>
                          {sub.prospectus && sub.prospectus.type}
                        </TableCell>
                        <TableCell>
                          {sub.prospectus && sub.prospectus.units}
                        </TableCell>
                        <TableCell>{sub.section || ""}</TableCell>
                        <TableCell>
                          {(sub && `${sub.total_students}/${sub.max_person}`) ||
                            ""}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={(subjectOffers.meta && subjectOffers.meta.total) || 0}
                page={
                  (subjectOffers.meta && subjectOffers.meta.current_page - 1) ||
                  0
                }
                rowsPerPageOptions={[25, 50, 100]}
                rowsPerPage={filters.limit}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={selected.length === 0 || enrollments.updating}
          onClick={handleSubmit}
        >
          {enrollments.updating ? <CircularProgress size={24} /> : "Submit"}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
          disabled={enrollments.updating}
        >
          Close
        </Button>
      </DialogActions>
    </FormDialog>
  );
}

SubjectOfferModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  values: PropTypes.object,
  courses: PropTypes.array,
  loads: PropTypes.array,
  setSubjectLoads: PropTypes.func,
};

export default SubjectOfferModal;
