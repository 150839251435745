import Http from "../utils/Http";

export const getNotifications = () => {
  return Http.get("/api/notifications");
};

export const markAsReadNotification = (id) => {
  return Http.get(`/api/notifications/markAsRead/${id}`);
};

export const getMaintenance = () => {
  return Http.get("/api/maintenance");
};
