import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import LogoutModal from "./components/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import * as userService from "../modules/admin/user/service";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import NoPhoto from "../assets/img/no_image.png";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router";
import { systemRoles } from "../utils/helpers";
import * as service from "./service";
import { Popper } from "@material-ui/core";
import { Fade } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { ClickAwayListener } from "@material-ui/core";
import NotificationTemplate from "./NotificationTemplate.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user.data);
  const roles = user.roles || [];

  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const [openNotif, setOpenNotif] = useState(false);
  const openProfile = Boolean(anchorEl);

  useEffect(() => {
    service.getNotifications().then((res) => {
      if (res.data) {
        setNotifications(res.data);
      }
    });

    if (
      !roles.includes(systemRoles.student) &&
      roles[0] &&
      !roles[0].includes("Instructor")
    ) {
      const timer = setInterval(() => {
        service.getNotifications().then((res) => {
          if (res.data) {
            setNotifications(res.data);
          }
        });
      }, 15000);

      return () => {
        clearInterval(timer);
      };
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    dispatch(userService.logout());
  };

  const handleOpenProfile = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLogoutModal = () => {
    setOpenLogoutModal(true);
    handleMenuClose();
  };

  const clickProfile = () => {
    history.push("/admin/user");
    handleMenuClose();
  };

  const clickSettings = () => {
    history.push("/admin/settings");
    handleMenuClose();
  };

  const handleOpenNotifications = (e) => {
    setNotifAnchorEl(e.currentTarget);
    setOpenNotif(!openNotif);
  };

  const handleMarkAsRead = (id) => {
    setNotifications(notifications.filter((notif) => notif.id !== id));
    service.markAsReadNotification(id);

    const item = notifications.filter((notif) => notif.id === id);

    if (item.length > 0) {
      const data = item[0].data || {};

      if (data.url) {
        setOpenNotif(false);
        history.push(data.url);
      }
    }
  };

  return (
    <div>
      <div className={classes.profile}>
        <div>
          <IconButton
            onClick={handleOpenNotifications}
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={notifications.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popper
            style={{
              zIndex: 9999,
              maxHeight: 300,
              overflow: "auto",
              maxWidth: 600,
            }}
            open={openNotif}
            anchorEl={notifAnchorEl}
            placement="bottom-end"
            transition
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={() => setOpenNotif(false)}>
                <Fade {...TransitionProps} timeout={500}>
                  <Paper className={classes.notificationBody}>
                    {notifications.length === 0 && (
                      <NotificationTemplate
                        notification={{
                          data: {
                            message: "No notifications at the moment",
                          },
                        }}
                      />
                    )}
                    {notifications.map((notification, index) => (
                      <NotificationTemplate
                        key={index}
                        handleClick={() => handleMarkAsRead(notification.id)}
                        notification={notification}
                      />
                    ))}
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </div>
        <div className={classes.divider}> </div>
        <Typography>{user.name}</Typography>
        <IconButton onClick={handleOpenProfile}>
          {user.image ? (
            <Avatar src={user.image} className={classes.avatar} />
          ) : (
            <Avatar src={NoPhoto} className={classes.avatar} />
          )}
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={openProfile}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={clickProfile}>
          <i className={`${classes.icon} fa fa-user`}></i> Profile
        </MenuItem>
        {user.roles &&
          (user.roles.includes(systemRoles.registrar) ||
            user.roles.includes(systemRoles.administrator)) && (
            <MenuItem onClick={clickSettings}>
              <i className={`${classes.icon} fa fa-cogs`}></i> Settings
            </MenuItem>
          )}

        <MenuItem onClick={handleOpenLogoutModal}>
          <i className={`${classes.icon} fa fa-sign-out`}></i> Logout
        </MenuItem>
      </Menu>

      <LogoutModal
        open={openLogoutModal}
        onClose={() => setOpenLogoutModal(false)}
        handleLogout={handleLogout}
      />
    </div>
  );
}
