import { CardContent } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Card } from "@material-ui/core";
import BasicDataTable from "../../../../components/BasicDataTable";
import React, { useEffect, useState } from "react";
import * as service from "../service";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

import {
  Avatar,
  // Switch
} from "@material-ui/core";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import { Fragment } from "react";
import { IconButton } from "@material-ui/core";
import ResetModal from "../components/ResetModal";
import { useDispatch } from "react-redux";
import { showNofications } from "../../../../modules/layouts/store/actionCreators";
import { handleResponseError } from "../../../../utils/helpers";
import FormField from "../../../../components/FormField";
import axios from "axios";
import { Grid } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

function PasswordReset() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({
    data: [],
    meta: {},
  });

  const [filters, setFilters] = useState({
    limit: 15,
    search: "",
  });

  const [resetting, setResetting] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [resetId, setResetId] = useState("");

  const columns = [
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value) => (
          <Fragment>
            <IconButton
              color="primary"
              edge="end"
              size="small"
              onClick={() => handleOpenResetModal(value)}
            >
              <VpnKeyIcon />
            </IconButton>
          </Fragment>
        ),
      },
    },
    {
      name: "name",
      label: "Name",
    },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (value) => (
    //       <Switch checked={parseInt(value) === 1} color="primary" />
    //     ),
    //   },
    // },
    {
      name: "image",
      label: " ",
      options: {
        customBodyRender: (value) =>
          value ? (
            <Avatar alt={value} src={value} />
          ) : (
            <Avatar>
              <InsertPhotoIcon />
            </Avatar>
          ),
      },
    },

    {
      name: "email",
      label: "Email",
    },
  ];

  useEffect(() => {
    fetchingData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchingData = (params = {}) => {
    setLoading(true);
    service
      .getAllUsers({ ...filters, status: 1, ...params })
      .then((res) => {
        if (res.data) {
          setUsers(res.data);
        }

        setLoading(false);
      })
      .catch(() => {
        if (!axios.isCancel) {
          setLoading(false);
        }
      });
  };

  const handleFilterChange = (name, value) => {
    if (name === "search") {
      fetchingData({ search: value });
    }

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangePage = (page) => {
    fetchingData({ page: page + 1 });
  };

  const handleChangeRowsPerPage = (limit) => {
    handleFilterChange("limit", limit);
    fetchingData({ limit });
  };

  const handleOpenResetModal = (id) => {
    setResetId(id);
    setOpenResetModal(true);
  };

  const handleConfirmResetPassword = (values) => {
    setResetting(true);
    service
      .resetPassword(resetId, values)
      .then((res) => {
        if (res.status === 200) {
          dispatch(showNofications("Sms password reset sent."));
          setUsers((prev) => ({
            ...prev,
            data: prev.data.map((item) =>
              item.id === res.data.data.id ? res.data.data : item
            ),
          }));
        }
        setOpenResetModal(false);
        setResetting(false);
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        setResetting(false);
      });
  };
  return (
    <div>
      <Card>
        <CardContent>
          <Box>
            <FormField
              label="Search"
              onChange={(e) => handleFilterChange("search", e.target.value)}
              value={filters.search}
            />
          </Box>
          <Box>
            {loading ? (
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            ) : (
              <BasicDataTable
                withPagination
                withIndex
                columns={columns}
                data={users.data || []}
                page={users.meta.current_page - 1}
                count={users.meta.total || 0}
                rowsPerPage={filters.limit}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </Box>
        </CardContent>
      </Card>

      <ResetModal
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
        loading={resetting}
        handleConfirm={handleConfirmResetPassword}
      />
    </div>
  );
}

export default PasswordReset;
