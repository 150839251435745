// import { handleResponseError } from "../../utils/helpers";
import Http from "../../utils/Http";
// import { showNofications } from "../layouts/store/actionCreators";
import * as actions from "./store/actionCreators";

export const getInformations = (params = {}) => {
  return (dispatch) => {
    dispatch(actions.requestFetchInformations);
    return Http.get("/api/informations", params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.fetchInformations(res.data || {}));
          return true;
        }
      })
      .catch((err) => {
        // dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failFetchInformations(err));
      });
  };
};
