const palletes = {
  error: "#e3342f",
  black: "#000",
  success: "#38c172",
  textSuccess: "#215f0c",
  white: "#fff",
  info: "#36b9cc",
  secondary: "#e2e3e5",
  primary: "#d4e6f9",
  textPrimary: "#144376",
  baseBlue: "#3f51b5",
  dangerText: "#FF0039",
  success_info: "#d9f0d1",
  warning: "#f16100",
  badgeWarning: "#FF7518",
  textColor: {
    gray: "#858796",
  },
};

export default palletes;
